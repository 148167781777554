$main-background-color:#0a192f;
$theme-el-color-one:#00A5D1;
$theme-el-color-two:#01C2C9;
$white-color:#fff;
$linear-bckgrnd-color: linear-gradient(to right, $theme-el-color-two 0%, $theme-el-color-one 100%);
$linear-hireBtn-color:linear-gradient(to right, #ee0979 0%, #ee0979  100%);        
$mobileSideMenuBackground:#0a9dc6;
         
             
         
         

 
 