.projectSection{
  width: 60%;
  height: 100%;
  z-index:  0 !important;
  margin: 0rem auto 2.5rem auto;
}
/*===================Media Queries======================*/
@media(max-width:52.5rem){
  .projectSection{
    width: 73%;
  }
}