@import "../styles/variables/colors.scss" ;

@mixin display-flex() {
    display : -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
  }
/*====Main Div*/
.projectBox{
    width: 100%;
    height: 100%;
    min-height: 400px;
    position: relative;
    margin: 0rem auto 8.1rem auto;
}
/*====Image wrapper div*/
.imgWrapper{
    opacity: .70;
}
.imgWrapper:hover{
    opacity: 1;
    cursor: pointer;
}
/*====Image Scale*/
.projectBoxImg{
    width: 65%;
    min-width: 100px;
    max-width: 600px;
    border-radius: 15px;
}
.title{
    font-size: clamp(12px, 12vw, 24px);
    text-align: right;
}
.title a{
    font-size: clamp(12px, 12vw, 24px);
    color:#c7c7c7;
    font-weight: 600;
}
.title a:hover{
    color: $theme-el-color-two;
}
.descriptionWrapper{
    color: #FCF7FF;
    background-color: #485696;
    border-radius: 15px;
    padding: 1.2rem;
    font-size: clamp(10px, 8vw, 16px);
    box-shadow: 0 10px 30px -15px #000;
    margin: 1.2rem 0rem 0rem 0rem;
    text-align: justify;
}
.rightSide{
    position: absolute;
    width:55%;
    right: 0%;
    top:5%;
}
.techStyles{
    color: $theme-el-color-two;
    right: 0%;
    text-align: right;
    margin: 0.7rem 0.3rem 0rem 0rem;
    @include display-flex();
    flex-wrap: wrap;
    justify-content: flex-end;
    font-size: 0.87rem;
}
.techItemStyles{
    margin: 0rem 0rem 0rem 0.9rem;
}
.github{
    font-size: 1.37rem;
    color: $theme-el-color-one;
    margin: 0.31rem;
}
.github:hover{
    color: $white-color;
}
.linkRow{
    right: 0%;
    margin: 0.625rem 0.31rem 0rem 0rem;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    font-size: 1.37rem;
    color: $theme-el-color-one;
}
.demo{
    font-size: 1rem;
    border: 1px solid $theme-el-color-one;
    background-color: transparent;
    border-radius: 2px;
    margin: 0.35rem ;
    padding: 0.25rem;
    padding-top: 0.18rem;
    //height: 28px;
    color: $theme-el-color-one;
}
.demo:hover{
    color: $white-color;
    border: 1px solid $white-color;
}
/*===================Media Queries=========================*/
@media screen and (max-width: 62.5rem) {
    .rightSide {
        position: absolute;
        width:70%;
        right: 0%;
        top:5%;
    }
}
@media (max-width:40rem){
    .projectBox{
        width: 100%;
        min-height: 700px;
        position: relative;
    }
    .projectBoxImg{
        width: 100%;
    }
    .rightSide{
        position: absolute;
        width:100%;
        height: 45%;
        top:36%;   
        margin-bottom: 6.25rem;     
    }
    .techStyles{
        left: 0%;
        text-align:left;
        margin: 1.2rem 0.31rem 0rem 0rem;       
        justify-content: flex-start !important;
    }
    .title{
       
        text-align: center !important;
    }
    .linkRow{       
        justify-content: center !important;
    }
}
@media (max-width:31.37rem){
    .rightSide{      
        top:28% ;    
    }   
    .title a{      
        font-size: 1.2rem;
        color:#c7c7c7;      
        font-weight: 600;
    }
    
}
