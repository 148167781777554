@import "../variables/colors.scss";
//Shared SCSS Code
@mixin gitHubButton{
    background-image: $linear-bckgrnd-color;
    border:0px;
    border-radius: 6px;
    width: 150px;
    height: 40px;
    font-size: 1.12rem;
    padding-top: 0.31rem !important;
    margin-top: 1.25rem!important;
    transition: all 0.5s ease 0s;
    opacity: 0;
    animation-name: showHeading;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-delay: 2s;
    animation-fill-mode: forwards;
     
  }
@mixin gitHubButtonHover{
    width: 170px;
    height: 45px;
    padding-top: 0.43rem !important;
    background-image: $linear-bckgrnd-color;
}
