//SASS-SCSS variables imported
@import"../../styles/variables/colors.scss" ;

//SCSS Code
  .headingWrapper {
    z-index:  0;
    margin:220px auto 0 auto;
    width:90%;
    display: flex;
    justify-content: space-between;
    height: 68vh;
    padding: 0 0 0 20%;
  }
  .introText{
    font-family: Montserrat, sans-serif;
    height: 300px;
    min-width: 300px;
  }
  .hi{
    color:#a3a3a3;
    font-size: clamp(18px, 4vw, 28px);
    opacity: 0;
    animation-name: showHeading;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-fill-mode: forwards;
  }
  .atif{
    color: #fff;
    font-size: clamp(40px, 8vw, 90px);
    font-weight:900;
    opacity: 0;
    animation-name: showHeading;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-delay: .6s;
    animation-fill-mode: forwards;
  }
  .frontEnd{
    color:#a3a3a3;
    font-size: clamp(18px, 4vw, 32px);
    opacity: 0;
    animation-name: showHeading;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-delay: 1.5s;
    animation-fill-mode: forwards;
  }
  .gitHubButton{
    width: 150px;
  height: 40px;
  font-size: 1.12rem;
  border: 0px;
  border-radius: 6px;
  padding-top: 0.31rem !important;
  margin-top: 1.25rem;
  opacity: 0;
  animation-name: showHeading;
  transition: all 0.5s ease 0s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 500ms;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  background-image: $linear-bckgrnd-color;

  &:hover {
    width: 170px;
    height: 45px;
    padding-top: 0.43rem;
    background-image: $linear-bckgrnd-color;
  }
  }
  .hireMe{
    width: 150px;
    height: 40px;
    padding: 0;
   // height: 40px;
    font-size: 1.12rem;
    //font-weight: 500;
    border: 0px;
    border-radius: 16px; 
    padding-top: 0.31rem !important;
    margin-top: 1.25rem;
    opacity: 0;
    animation-name: showHeading;
    transition: all 0.5s ease 0s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-delay: 2s;
    animation-fill-mode: forwards;
    background-image: $linear-hireBtn-color; 
    color: #fff !important;
    &:hover {
      width: 170px;
    
    padding-top: 0.43rem;
      color:#fff !important;   
      background-image: $linear-hireBtn-color;
      text-decoration: none !important;
      border: none !important;
    }
  } 
/*===================Media Queries======================*/
  @media(max-width:26.37rem){
    .gitHubButton{
      width: 120px;
      height: 40px;
      font-size: 1rem;
    }
    .gitHubButton:hover{
      width: 140px;
      height: 45px;
    }
  }
/*====================Key Frames===========================*/
@keyframes showHeading {
  0% {     transform: translateY(-10px);opacity: 0; }
  100% {     transform: translateY(0px);opacity: 1;  }
}