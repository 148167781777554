
//SASS-SCSS variables imported
@import"../../styles/variables/colors.scss" ;

//SCSS Code
  .skillsWrapper{
    display: block;
    width: 100%;
    height: 50vh;
    min-width: 250px;
    min-height: 550px;
  }
  .skillParent{
    width: 70%;
    height: 70%;
    max-width: 900px;
    min-height: 450px;
    position: relative;
    margin: auto;
    margin-top: 1.5rem;
  }
  .skillsChildOne{
    width: 50%;
    height: 100%;
    position:absolute;
    left:0;
  }
  .skillsChildTwo{
    width: 50%;
    height: 100%;
    position:absolute;
    right: 0;    
  }
  .skillBasic{
    text-align: center; 
    border-radius: 4px;
    color: #fff;  
   
    //padding:0px;
    //padding-top: 2px;    
    position:absolute;
    opacity: 0;
    animation-name: fadeIn;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
    &:hover{
      -webkit-transform: scale(1.2);
      transform: scale(1.2);
      transition: transform 2s;
    }
  }
  .skillJS{      
    width:170px;    
    height: 46px;
    padding: 4px;
    font-size: 1.5rem;     
    top: 10px;
    left:0px;
    background-color: #E85F5C;
    animation-duration: 2s;
    animation-delay: 1s;
  }
  .skillReact{
    width:160px;    
    height: 40px;
    padding: 3px;
    color: $white-color;
    font-size: 1.5rem;
    top: 130px;
    right: 10px;
    background-color: #F68E5F;
    animation-duration: 1s;
    animation-delay: 1.4s;
  }
  .skillHtml{
    width:125px;
    height: 33px;
    font-size: 1.37rem;
    top: 260px;
    left:0px;
    background-color: #E43F6F;
    animation-duration: 2s;
    animation-delay: 1s;
  }
  .skillCss{
    width:130px;
    height: 38px;
    padding: 2px;
    font-size: 1.5rem;
    top: 390px;
    right: 10px;
    background-color: #3581B8;
    animation-duration: 600ms;
    animation-delay: 1s;
  }
  .skillRedux{
    width:125px;
    height: 33px;
    font-size: 1.37rem;
    top: 0px;
    right: 0px;
    background-color: #485696;
    animation-duration: 2s;
    animation-delay: 1s;
  }
  .skillNode{
    width:140px;
    height: 38px;
    font-size: 1.5rem;
    top: 100px;
    left: 10px;
    background-color: #00B4D8;
    animation-duration: 2s;
    animation-delay: 800ms;
  }
  .skillMySql{
    width:180px;
    height: 38px;
    padding: 2px;
    font-size: 1.5rem;
    top: 200px;
    right: 0px;
    background-color: #4FB477;
    animation-duration: 100ms;
    animation-delay: 2s;
  }
  .skillSql{
    width:135px;
    height: 38px;
    padding: 3px;
    font-size: 1.37rem;
    top: 300px;
    left: 10px;
    background-color: #694873;
    animation-duration: 500ms;
    animation-delay: 1.5s;
  }
  .skillCars{
    width:135px;
    height: 38px;    
    padding: 3px;
    font-size: 1.37rem;
    top: 400px;
    right: 0px;
    background-color: #2A6041;
    animation-duration: 500ms;
    animation-delay:2s;
  }
/*===================Media Queries======================*/
  @media(max-width:56.25rem){
    .skillParent{
      width: 50%;
      height: 70%;
    }
  }
  @media (max-width:40rem){
    .skillParent{
      width: 65%;
      height: 70%;
    }
    .skillJS{
      width:100px;
      height: 28px;
      padding: 2px;
      font-size: 1rem;    
    }
    .skillReact{
      width:100px;
      height: 28px;
      padding: 2px;
      font-size: 1rem;
    }
    .skillHtml{
      width:100px;
      height: 28px;
      padding: 2px;
      font-size: 1rem;
    }
    .skillCss{
      width:100px;
      height: 28px;
      padding: 3px;
      font-size: 1rem;
    }
    .skillRedux{
      width:100px;
      height: 28px;
      padding: 2px;
      font-size: 1rem;
    }
    .skillNode{
      width:100px;
      height: 28px;
      padding: 2px;
      font-size: 1rem;
    }
    .skillMySql{
      width:120px;
      height: 28px;
      padding: 2px;
      font-size: 1rem;
    }
    .skillSql{
      width:100px;
      height: 28px;
      padding: 2px;
      font-size: 1rem;
    }
    .skillCars{
      width:110px;
      height: 28px;
      padding: 2px;
      font-size: 1rem;
    }
  }
  @keyframes fadeIn {
      0% { opacity: 0; }
      100% { opacity: 1; }
  }