//SASS-SCSS variables imported
@import"../../styles/variables/colors.scss" ;

//SCSS Code
.menuStyle{
  width: 400px;
  background-color: transparent;
  text-decoration: none;
  border: none !important;
}
.ant-menu-horizontal, .ant-menu-item::after, .ant-menu-submenu::after { border: none !important;}        

.hireMe{
  width: 85px; 
  padding:0.72rem; 
  font-size: 0.92rem;  
  border: 0px;
  border-radius: 16px;   
  opacity: 0;
  animation-name: showHeading;
  transition: all 0.5s ease 0s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 500ms;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  background-image: $linear-hireBtn-color;  
  //background-color: transparent; 
  cursor: pointer;  
  color:#fff !important;   
  font-weight: 500;
  //letter-spacing: 1px;
}  
.hireMe:hover {
  width: 90px;  
  color:#fff !important;   
  background-image: $linear-hireBtn-color; 
}   
.menu{
  width:'70%';
  padding: 0rem 1.25rem 0rem 0rem; 
  position: absolute;
  top: 0.62rem;
  right:0rem;
  border-bottom: 0;
  font-size: 1.12rem;
  background:transparent;
  opacity: 0;
  animation-name: showHeading;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 500ms;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;  
  a{
    color: #0E0E52 !important;
    height: 46px;
    line-height: 46px;    
  }
  a:hover{
      color: #fff !important;
      font-weight: 600;     
  }
}
.menuItem, .menuItem::before, .menuItem::after{
  border-bottom: none !important;
  font-weight: 600;
 text-decoration: none !important;
}
/*===================Media Queries======================*/
@media(max-width:43.75rem){
  .menu{
    opacity: 0;
  }
  .menuStyle{
    opacity: 0;
  }
}
/*====================Key Frames===========================*/
@keyframes showHeading {
  0% {     transform: translateY(-10px);opacity: 0; }
  100% {     transform: translateY(0px);opacity: 1;  }
}