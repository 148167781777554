.upBtn{
    line-height: 20px;
    border-radius: 4px;
    color: #01C2C9;
    text-align: center;
    font-size: 2.5rem;
    position: absolute;
    left: 100%;
    bottom: 70%;
    box-shadow: 12px 12px 24px rgba(79,209,197,.64);
}  
@media(max-width:26.37rem){
.upBtn{
    left: 10px;
    font-size: 30px;
}
}
@media(max-width:23.75rem){
    .upBtn{
        left: 0px;
    }
    }