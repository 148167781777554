//SASS-SCSS variables imported
@import'../../styles/variables/colors.scss';

@mixin display-flex() {
  display : -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
//SCSS Code
.aboutSection {
  width: 60%;
  height: 80%;
  z-index: 0 !important;
  margin: 0rem auto 3.12rem auto;
}
.aboutContent {
  width: 100%;
  margin: 0rem auto 1.87rem auto;
  font-size: 1.12rem;
  
}
.aboutSubContent_flex {  
  margin-top: 5rem;
  //background-color: #00E2BE;
  width: 100%;
  @include display-flex();
  flex-direction: row;
  min-height: 400px;
  text-align: justify;
}

.selfImgWrapper {
  background-color: #0f213d;
  border: 3px solid #00E2BE;
  width: 310px;
  height: 288px;
  opacity: 0.75;
  &:hover {
    opacity: 1;
  }
}
.selfImg {
  border-radius: 4px;
  width: 300px;
  height: 278px;
  margin: -1.87rem 0rem 0rem 1.87rem;
  &:hover {
    border: 3px solid $white-color;
  }
}
.aboutText {
  width: 100%;
  min-width: 250px;
  margin: 0rem 2.5rem 0rem 2.5rem;
  color: #e1e1e1;
  b {
    color: $theme-el-color-two;
  }
  p {
    margin: 0.625rem 0rem 0rem 0rem;
  }
}
.resumeBtn {
  width: 150px;
  height: 40px;
  font-size: 1.12rem;
  border: 0px;
  border-radius: 6px;
  padding-top: 0.31rem !important;
  margin-top: 1.25rem;
  opacity: 0;
  animation-name: showHeading;
  transition: all 0.5s ease 0s;
  animation-iteration-count: 1;
  animation-timing-function: linear;
  animation-duration: 500ms;
  animation-delay: 2s;
  animation-fill-mode: forwards;
  background-image: $linear-bckgrnd-color;

  &:hover {
    width: 170px;
    height: 45px;
    padding-top: 0.43rem;
    background-image: $linear-bckgrnd-color;
  }
}
/*===================Media Queries======================*/
@media (max-width: 62.5rem) {
  .aboutSubContent_flex {
    flex-direction: column;
  }
  .aboutText {
    margin: 0.93rem 0rem 0rem 0rem;
    padding-left: 0.85rem;
    padding-right: 0.85rem;
    padding-bottom: 0.85rem;
    min-width: 300px;
    font-size: 1rem;
  }
}
@media (max-width: 52.5rem) {
  .aboutSection {
    min-width: 300px;
  }
  .selfImg {
    width: 260px !important;
    height: 238px;
  }
  .selfImgWrapper {
    width: 270px;
    height: 248px;
  }
  .aboutSubContent_flex {
    min-height: 400px;
  }
  .aboutText {
    margin: 0.93rem 0rem 0rem 0rem;
    padding-left: 0.85rem;
    padding-right: 0.85rem;
    padding-bottom: 0.85rem;
    min-width: 300px;
    font-size: 1rem;
    
  }
}
/*1rem=16px*/
@media (max-width: 26.37rem) {
  .aboutSection {
    min-width: 250px;
    margin-left: 2.5rem;
  }
  .selfImg {
    width: 230px;
    height: 208px;
  }
  .selfImgWrapper {
    width: 240px;
    height: 218px;
    margin-left: 15px;
  }
  .aboutText {
    margin: 0.93rem 0rem 0rem 0.31rem;
    font-size: 0.87rem;    
    padding-left: 0.85rem;
    padding-right: 0.85rem;
    padding-bottom: 0.85rem;
  }
  .resumeBtn {
    width: 120px;
    height: 40px;
    font-size: 1rem;
  }
  .resumeBtn:hover {
    width: 140px;
    height: 45px;
  }
}
 /*====================Key Frames===========================*/
@keyframes showHeading {
  0% {
    transform: translateY(-10px);
    opacity: 0;
  }
  100% {
    transform: translateY(0px);
    opacity: 1;
  }
}
/*# sourceMappingURL=AboutSection.module.css.map */
