//SASS-SCSS variables imported
@import"./styles/variables/colors.scss";

//SCSS Code
  .footerStyle{
    width: 100%;
    text-align: center;
    background-color: #EDF2F4;
    position:absolute;
    bottom: 0;
    margin: 40px 0 0 0;
    padding: 8px 0 0px 0;
    font-size: 16px;
    color: $white-color;
    height: 45px;
    background-image: $linear-bckgrnd-color;
  }
  .layoutStyle{
    width: 100% !important;
    padding: 0;
    margin: 0 auto 0 auto ;
    background-color: #0f213d;
  }
  .imageWrapper{
    width: 100% !important;
    position: absolute;
    right: 0px;
    top: 0px;
    z-index: 0;
  }
