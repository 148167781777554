//SASS-SCSS variables imported
@import"../../styles/variables/colors.scss" ;
@import"../../styles/sharedStyle/linearBtn.scss";

//SCSS Code
.itsyBitsyContainer{
  height: 100vh;
  width: 100%;
  min-height: 200px;
  margin: 0rem;
  color:#e1e1e1;
  text-align: center;
  font-size: 1.12rem;
  background-color: #7769FF;
  scroll-behavior: auto;
  overflow: scroll;
}
.headerContainer{
    width: 100%; 
    height: 80px;
    min-height: 60px;      
    //margin: 0rem auto 1.87rem auto;
    color:#e1e1e1;
    text-align: center;
    font-size: 1.5rem;
    //background-color: grey;
    display: block;
    padding-top: 20px;
  }
.heading{
    font-weight:37rem;
    font-size: 2.5rem;
    color: #e1e1e1;
    letter-spacing: 0px;
    // word-spacing: 15px;
   // font-variant: small-caps;
    text-align: center;
    margin: 0rem;
}
.contentContainer{
    //min-width: 650px;
    width: 100%;
    min-height: 500px;  
    height: 70vh;
    //background-color: green;
    padding: 5px;
    z-index: 2;
    display: flex;
    flex-direction: row;    
    justify-content: center; 
    align-items: center;
    
  }
  .appDescriptionContainer{
    width: 48%;
    min-height: 400px;  
   // background-color: orange;
    padding: 5px;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;  
    
  }
  .description{
    width: 45%;
    min-height: 150px;      
   //background-color: grey;
    flex-direction: column;    
    justify-content: flex-start; 
  }
  .descText1{
    color:#fff;
    font-size: 1.2rem;
    text-align: left;
    font-weight: bold;
    padding: 1px;
    margin: 0px;
  }
  .descText2{
    color:#423B86;
    font-size: 1.8rem;
    text-align: left;
    //font-weight: bold;
    letter-spacing: 5;
    padding: 1px;
    margin: 0px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  .descText3{
    color:#fff;
    font-size: 2.5rem;
    //font-weight: bold;
    text-align: left;
    letter-spacing:5;
    padding: 1px;
    margin: 0px;
    font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
  }
  .googlePlayImageContainer{
    width: 22%;
    min-height: 40px;       
    //background-color: brown;   
    justify-content: flex-start;
    align-items: flex-start;
  }
  .googlePlayImageBox{
    //margin:40px;
    width: 140px;
    height: 34px;       
    //background-color: blue;       
    margin: 20px;
    
  }
  .mobileImageContainer{
    width: 30%;
    min-height: 150px;  
    //background-color: purple;
    padding: 5px;
    z-index: 3;
    display: flex;
    align-items: center;  
    justify-content: center;   
    
  }
  
  .imageBox{
    width: 70%;
    min-height: 180px;       
   // background-color: blue;
    align-self: center;
    z-index: 4;        
  }  
  .privacyContainer{
    width: 100%;
    height: 20vh;
   // background-color: brown;
    padding: 5px;
    z-index: 2;
    display: flex;    
    justify-content: center;   
    align-items: center;
    
  }
  .privacyText{
    color:#fff;
    font-size: 1rem;
    //font-weight: 800;
    text-align: center;    
    padding: 1px;
    margin: 0px;
  }

/*===================Media Queries======================*/
@media(max-width:84rem){
    .appDescriptionContainer{
        width: 48%;
        min-height: 400px;  
      //  background-color: orange;
        padding: 5px;
        z-index: 2;                           
        
      }
      .description{
        width: 82%;
        min-height: 160px;       
       // background-color: grey;
        flex-direction: column;    
        justify-content: flex-start;
       
       
      }
      .descText1{
        color:#fff;
        font-size: 1rem;
        text-align: left;
        font-weight: bold;
        padding: 1px;
        margin: 0px;
      }
      .descText2{
        color:#423B86;
        font-size: 1.6rem;
        text-align: left;
        //font-weight: bold;
        letter-spacing: 5;
        padding: 1px;
        margin: 0px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      .descText3{
        color:#fff;
        font-size: 2rem;
        //font-weight: bold;
        text-align: left;
        letter-spacing:5;
        padding: 1px;
        margin: 0px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      .googlePlayImageContainer{
        width: 82%;
        min-height: 40px;       
       // background-color: brown;   
        justify-content: center;
        align-items: center;
      }
      .googlePlayImageBox{
        //margin:40px;
        width: 140px;
        height: 34px;       
        //background-color: blue;       
        margin: 20px;
        
      }
      .mobileImageContainer{
        width: 48%;
        min-height: 350px;       
      //  background-color: purple;
        padding: 5px;
        z-index: 3;
        display: flex;
        align-items: center;  
        justify-content: center;   
        
      }
      .imageBox{
        width: 70%;
        min-height: 180px;       
        //background-color: blue;
        align-self: center;
        z-index: 4;        
      }
      .privacyText{
        color:#fff;
        font-size: 1rem;
        //font-weight: 800;
        text-align: center;        
        padding: 1px;
        margin: 0px;
      }
}
@media(max-width:66rem){
    .appDescriptionContainer{
        width: 48%;
        min-height: 400px;  
      //  background-color: orange;
        padding: 5px;
        z-index: 2;                           
        
      }
      .description{
        width: 82%;
        min-height: 160px;       
       // background-color: grey;
        flex-direction: column;    
        justify-content: flex-start;
       
       
      }
      .descText1{
        color:#fff;
        font-size: 1rem;
        text-align: left;
        font-weight: bold;
        padding: 1px;
        margin: 0px;
      }
      .descText2{
        color:#423B86;
        font-size: 1.6rem;
        text-align: left;
        //font-weight: bold;
        letter-spacing: 5;
        padding: 1px;
        margin: 0px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      .descText3{
        color:#fff;
        font-size: 2rem;
        //font-weight: bold;
        text-align: left;
        letter-spacing:5;
        padding: 1px;
        margin: 0px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      .googlePlayImageContainer{
        width: 82%;
        min-height: 40px;       
       // background-color: brown;   
        justify-content: center;
        align-items: center;
      }
      .googlePlayImageBox{
        //margin:40px;
        width: 140px;
        height: 34px;       
        //background-color: blue;       
        margin: 20px;
        
      }
      .mobileImageContainer{
        width: 48%;
        min-height: 350px;       
      //  background-color: purple;
        padding: 5px;
        z-index: 3;
        display: flex;
        align-items: center;  
        justify-content: center;   
        
      }
      .imageBox{
        width: 70%;
        min-height: 180px;       
        //background-color: blue;
        align-self: center;
        z-index: 4;        
      }
      .privacyText{
        color:#fff;
        font-size: 1rem;
        //font-weight: 800;
        text-align: center;        
        padding: 1px;
        margin: 0px;
      }
}
@media(max-width:52.5rem){
    .appDescriptionContainer{
        width: 48%;
        min-height: 400px;  
       // background-color: orange;
        padding: 5px;
        z-index: 2;                           
        
      }
      .description{
        width: 82%;
        min-height: 160px;       
        //background-color: grey;
        flex-direction: column;    
        justify-content: flex-start;
       
       
      }
      .descText1{
        color:#fff;
        font-size: 1rem;
        text-align: left;
        font-weight: bold;
        padding: 1px;
        margin: 0px;
      }
      .descText2{
        color:#423B86;
        font-size: 1.6rem;
        text-align: left;
        //font-weight: bold;
        letter-spacing: 5;
        padding: 1px;
        margin: 0px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      .descText3{
        color:#fff;
        font-size: 2rem;
        //font-weight: bold;
        text-align: left;
        letter-spacing:5;
        padding: 1px;
        margin: 0px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      .googlePlayImageContainer{
        width: 82%;
        min-height: 40px;       
       // background-color: brown;   
        justify-content: center;
        align-items: center;
      }
      .googlePlayImageBox{
        //margin:40px;
        width: 140px;
        height: 34px;       
        //background-color: blue;       
        margin: 20px;
        
      }
      .mobileImageContainer{
        width: 48%;
        min-height: 400px;       
        //background-color: purple;
        padding: 5px;
        z-index: 3;
        display: flex;
        align-items: center;  
        justify-content: center;   
        
      }
      .imageBox{
        width: 70%;
        min-height: 180px;       
       // background-color: blue;
        align-self: center;
        z-index: 4;        
      }
      .privacyText{
        color:#fff;
        font-size: 1rem;
        //font-weight: 800;
        text-align: center;        
        padding: 1px;
        margin: 0px;
      }
}

@media (max-width: 38rem){
    .descText1{
        color:#fff;
        font-size: 0.8rem;
        text-align: left;
        font-weight: bold;
        padding: 1px;
        margin: 0px;
      }
      .descText2{
        color:#423B86;
        font-size: 1.1rem;
        text-align: left;
        //font-weight: bold;
        letter-spacing: 5;
        padding: 1px;
        margin: 0px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }
      .descText3{
        color:#fff;
        font-size: 1.5rem;
        //font-weight: 800;
        text-align: left;
        letter-spacing:5;
        padding: 1px;
        margin: 0px;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
        
      }
    .googlePlayImageContainer{
        width: 82%;
        min-height: 30px;       
        //background-color: brown;   
        justify-content: center;
        align-items: center;
      }
      .googlePlayImageBox{
        //margin:40px;
        width: 100px;
        height: 30px;       
        //background-color: blue;       
        //margin: 20px;
        
      }
      .privacyText{
        color:#fff;
        font-size: 1rem;
        //font-weight: 800;
        text-align: center;       
        padding: 1px;
        margin: 0px;
      }
}
