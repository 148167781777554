//SASS-SCSS variables imported
@import'../../styles/variables/colors.scss';

@mixin display-flex() {
  display : -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}
//SCSS Code
.clientSection {
  width: 70%;
  height: 100%;
  z-index: 0 !important;
  margin: 0rem auto 3.12rem auto;  
  
}
.servicesContainer{
  width: 100%;
  height: 600px;
  //display: flex;
  //justify-content: center;
 // align-items: center;
  //background-color: orange;
  z-index: 1;
}
.servicesContentContainer{
  min-width: 650px;
  height: 600px;
  //background-color: green;
  padding: 5px;
  z-index: 2;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;   
  
}
.box{
  width: 350px;
  height:230px;      
  //margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
  padding: 0.2rem;
  //background-color:grey; 
   display: flex; 
   flex-direction: column;    
   justify-content: flex-start;
   align-items: center;
   overflow: hidden     
}
.circleWrapper{ 
  //display: flex;
   width: 100px;
   height:100px;      
   margin: 0.1rem;
  // background-color:purple; 
   display: flex;     
   justify-content: center;
 }
 .circle{
   display: flex;
   width: 90px;
   height:90px;
   //background-color:green;
   justify-content: center;
   align-self: center;
   border:2px solid #00E2BE;
  border-radius:50%;
 }
 .servicesIcon{
  font-size: 2.65rem;
  color:#00E2BE;
 }
 .imageSize{
  width: 100px;
   height:100px;
   align-self: center;
   background-color:green;  
   
 }
 .title{
  color:#0094D6;
  font-size: 1rem;
  text-align: center;
  padding: 2px;
  margin: 0px;
  //letter-spacing: 2px;
 }
 .description{
  color:#fff;
  font-size: 0.85rem;
  text-align: center;
  padding: 1px;
  margin: 0px;
 }
 
/*===================Media Queries======================*/
@media (max-width: 104rem) {
  .servicesContentContainer{
    min-width: 650px;
    height: 600px;
    //background-color: green;
    padding: 5px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;   
    
  }
  .box{
    width: 300px;
    height:230px;      
    //margin-top: 0.5rem;
      margin-right: auto;
      margin-left: auto;
    padding: 0.2rem;
    //background-color:grey; 
     display: flex; 
     flex-direction: column;    
     justify-content: flex-start;
     align-items: center;
     overflow: hidden     
  }
  .circleWrapper{ 
    //display: flex;
     width: 100px;
     height:100px;      
     margin: 0.1rem;
     //background-color:purple; 
     display: flex;     
     justify-content: center;
   }
   .circle{
    display: flex;
    justify-content: center;
     width: 90px;
     height:90px;
     //background-color:green;
     align-self: center;
     border:2px solid #00E2BE;
    border-radius:50%;
   }   
   .title{
    color:#0094D6;
    font-size: 1rem;
    text-align: center;
    padding: 2px;
    margin: 0px;
    //letter-spacing: 2px;
   }
   .description{
    color:#fff;
    font-size: 0.85rem;
    text-align: center;
    padding: 1px;
    margin: 0px;
   }
 
}
@media (max-width: 84rem) {
  .servicesContentContainer{
    min-width: 650px;
    height: 600px;
    //background-color: green;
    padding: 5px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;  
    
  }
  .box{
    width: 230px;
    height:230px;      
    //margin-top: 0.5rem;
      margin-right: auto;
      margin-left: auto;
    padding: 0.2rem;
    //background-color:grey; 
     display: flex; 
     flex-direction: column;    
     justify-content: flex-start;
     align-items: center;
     overflow: hidden     
  }
  .circleWrapper{ 
    //display: flex;
     width: 100px;
     height:100px;      
     margin: 0.1rem;
     //background-color:purple; 
     display: flex;     
     justify-content: center;
   }
   .circle{
    display: flex;     
    justify-content: center;
     width: 90px;
     height:90px;
     //background-color:green;
     align-self: center;
     border:2px solid #00E2BE;
    border-radius:50%;
   }  
   .title{
    color:#0094D6;
    font-size: 1rem;
    text-align: center;
    padding: 2px;
    margin: 0px;
    //letter-spacing: 2px;
   }
   .description{
    color:#fff;
    font-size: 0.85rem;
    text-align: center;
    padding: 1px;
    margin: 0px;
   }
}
@media (max-width: 66rem) {
  .servicesContentContainer{
    min-width: 650px;
    height: 600px;
    //background-color: green;
    padding: 5px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;       
  }
  .box{
    width: 180px;
    height:220px;      
    //margin-top: 0.5rem;
      margin-right: auto;
      margin-left: auto;
    padding: 0.2rem;
    //background-color:grey; 
     display: flex; 
     flex-direction: column;    
     justify-content: flex-start;
     align-items: center;
     overflow: hidden     
  }
  .circleWrapper{ 
    //display: flex;
     width: 100px;
     height:100px;      
     margin: 0.1rem;
     //background-color:purple; 
     display: flex;     
     justify-content: center;
   }
   .circle{
    display: flex;
    justify-content: center;
     width: 90px;
     height:90px;
     //background-color:green;
     align-self: center;
     border:2px solid #00E2BE;
    border-radius:50%;
   }   
   .servicesIcon{
    font-size: 1.85rem;
    color:#00E2BE;
   }
   .title{
    color:#0094D6;
    font-size: 1rem;
    text-align: center;
    padding: 2px;
    margin: 0px;
    //letter-spacing: 2px;
   }
   .description{
    color:#fff;
    font-size: 0.85rem;
    text-align: center;
    padding: 1px;
    margin: 0px;
   }
}
@media (max-width: 52.5rem) {
  .servicesContentContainer{
    min-width: 470px;
    height: 600px;
    //background-color: green;
    padding: 5px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap; 
    
  }
  .box{
    width: 190px;
    height:190px;      
    //margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0.2rem;
    //background-color:grey; 
     display: flex; 
     flex-direction: column;    
     justify-content: flex-start;
     align-items: center;
     overflow: hidden     
  }
  .circleWrapper{ 
    //display: flex;
     width: 90px;
     height:90px;      
     margin: 0.1rem;
     //background-color:purple; 
     display: flex;     
     justify-content: center;
   }
   .circle{
    display: flex;
    justify-content: center;
     width: 80px;
     height:80px;
     //background-color:green;
     align-self: center;
     border:2px solid #00E2BE;
    border-radius:50%;
   }  
   .servicesIcon{
    font-size: 1.85rem;
    color:#00E2BE;
   } 
   .title{
    color:#0094D6;
    font-size: 0.95rem;
    text-align: center;
    padding: 2px;
    margin: 0px;
    //letter-spacing: 2px;
   }
   .description{
    color:#fff;
    font-size: 0.80rem;
    text-align: center;
    padding: 1px;
    margin: 0px;
   }
}

/*1rem=16px*/
@media (max-width: 38rem) {
  .servicesContentContainer{
    min-width: 300px;
    height: 600px;
    //background-color: green;
    padding: 5px;
    z-index: 2;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;    
    
  }
  .box{
    width: 140px;
    height:190px;      
   // margin-top: 0.5rem;
    margin-right: auto;
    margin-left: auto;
    padding: 0.2rem;
    //background-color:grey; 
     display: flex; 
     flex-direction: column;    
     justify-content: flex-start;
     align-items: center;
     overflow: hidden
     
  }
  .circleWrapper{ 
    //display: flex;
     width: 80px;
     height:80px;      
     margin: 0.1rem;
    // background-color:purple; 
     display: flex;     
     justify-content: center;
   }
   .circle{
    display: flex;
    justify-content: center;
     width: 70px;
     height:70px;
     //background-color:green;
     align-self: center;
     border:2px solid #00E2BE;
    border-radius:50%;
   }
   .servicesIcon{
    font-size: 1.85rem;
    color:#00E2BE;
   }
   .title{
    color:#0094D6;
    font-size: 0.8rem;
    text-align: center;
    padding: 2px;
    margin: 0px;
    //letter-spacing: 2px;
   }
   .description{
    color:#fff;
    font-size: 0.8rem;
    text-align: center;
    padding: 1px;
    margin: 0px;
   }
}
/*1rem=16px*/
