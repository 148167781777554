//SASS-SCSS variables imported
@import"../../styles/variables/colors.scss" ;

//SCSS Code
.sideMenu{
    position: fixed;
    left: 1%;
    bottom: 0%;
    margin: 0rem 0rem 0.93rem 1.25rem;
    text-align: center;
    opacity: 0;
    animation-name: showHeading;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}
.sideMenuItem{
    font-size: 1.62rem;
    margin-bottom: 0.62rem;
    z-index: 10;
    color: $theme-el-color-one !important;
}
.sideMenuItem a{
    color:$theme-el-color-one !important;
}
.sideMenuItem a:hover{
    color: #DDA448 !important;
    font-size: 1.75rem;
}
.sideMenuItemText{
    font-size: 0.87rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    margin: 0rem auto 0rem auto;
    color: $theme-el-color-one !important;
}
.sideMenuItemText:hover{
    color: #DDA448 !important;
    font-size: 0.93rem;
}
.sideMenuLine{
    border-right: solid 1px $theme-el-color-one ;
    height: 20px;
    width: 1px;
    margin: 0.62rem auto 0.62rem auto;
}
/*====================Key Frames===========================*/
@keyframes showHeading {
  0% {     transform: translateY(-10px);opacity: 0; }
  100% {     transform: translateY(0px);opacity: 1;  }
}
@keyframes sideMenuAnimation {
  0% {     transform: translateY(-5px);}
  100% {     transform: translateY(0px); }
}