.heading{
    font-weight:37rem;
    font-size: 2.2rem;
    color: #0094D6;
    letter-spacing: 1px;
    word-spacing: 15px;
    font-variant: small-caps;
    text-align: center;
    margin: 0rem;
}
@media(max-width:46.4rem){
    .heading{
        font-size: 1.8rem;
        word-spacing: 10px;
    }
}

