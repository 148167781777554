//SASS-SCSS variables imported
@import"../../styles/variables/colors.scss" ;
@import"../../styles/sharedStyle/linearBtn.scss";

//SCSS Code
.contactSection{
  height: 100vh;
  width: 60%;
  min-height: 200px;
  margin: 0rem auto 1.87rem auto;
  color:#e1e1e1;
  text-align: center;
  font-size: 1.12rem;
}
.gitHubButton{
  @include gitHubButton();
}
.gitHubButton:hover{
  @include gitHubButtonHover();
}

/*===================Media Queries======================*/
@media (max-width: 26.37rem){
  .contactSection{
    font-size: 0.87rem;
  }
  .gitHubButton{
    width: 120px;
    height: 40px;
    font-size: 0.87rem;
    padding-top: 0.312rem !important;
  }
  .gitHubButton:hover{
    width: 140px;
    height: 45px;
  }
}
/*====================Key Frames===========================*/
@keyframes showHeading {
  0% {     transform: translateY(-10px);opacity: 0; }
  100% {     transform: translateY(0px);opacity: 1;  }
}