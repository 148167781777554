//SASS-SCSS variables imported
@import"../../../styles/variables/colors.scss";

//SCSS Code
.menuButton{    
    opacity: 0;    
    cursor: pointer;   
}
.resumeBtn {
    width: 120px;
    //height: 30px;
    font-size: 0.91rem;
    border: 0px;
    border-radius: 6px;
    //padding-top: 0.31rem !important;
    //margin-top: 1.25rem;
    opacity: 0;
    animation-name: showHeading;
    transition: all 0.5s ease 0s;
    animation-iteration-count: 1;
    animation-timing-function: linear;
    animation-duration: 500ms;
    animation-delay: 0s;
    animation-fill-mode: forwards;
    background-image: $linear-hireBtn-color;
  
    &:hover {
      width: 120px;    
      background-image: $linear-bckgrnd-color;
      text-decoration: none;
    }
  }
.menu{
    width: 150px;
    left:40px;
    position: absolute;
    top: 28px; 
    background-color: #0F213D !important;
    border: 1px solid #00A5D1 !important;    
    text-decoration: none !important;
}
.menuItem a{
    color:$white-color;
    text-decoration: none;
}
@media (max-width:43.75rem) {
    .menuButton{
        left: 20px;
        position: absolute;
        font-size: 40px;
        color: $theme-el-color-one;
        opacity: 1;
        
    }
    
}
 /*====================Key Frames===========================*/
 @keyframes showHeading {
    0% {
      transform: translateY(-10px);
      opacity: 0;
    }
    100% {
      transform: translateY(0px);
      opacity: 1;
    }
  }
